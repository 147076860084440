import React, {useState} from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import HomePage from './pages/HomePage';
import AboutUs from './pages/AboutUs';
import StickyNavbar from './components/StickyNavbar';

import './scss/App.scss';

const App = () => {
  return (
      <Router>
        <div className="App">
          <StickyNavbar />
          <Header />
          <Routes>
            <Route path="/" exact element={<HomePage />} />
          </Routes>
          <Footer />
        </div>
      </Router>
  );
}

export default App;
