import React from 'react';
import data from '../data/tarpax-services';
import { Container, Image, Row, Col } from 'react-bootstrap';
import ReactHtmlParser from 'react-html-parser';

const OurServices = () => {
    return (
        <Container className="my-5">
            <h1>{data.services_title}</h1>
            <Row>
                <Col className="col-12 col-md-4 animate__animated animate__backInLeft">
                    <Image className="img-fluid rounded-5" src={data.services_image} alt={data.services_title} />
                </Col>
                <Col className="justify-content-start col-12 col-md-8 animate__animated animate__backInRight">
                    <p className="text-start fs-5">{ReactHtmlParser(data.services_body)}</p>
                </Col>
            </Row>
        </Container>
    );
};

export default OurServices;
