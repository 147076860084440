import React from 'react';
import { Container, Row, Col, CardImg } from 'react-bootstrap';
import data from '../data/tarpax-services';
import ReactHtmlParser from 'react-html-parser';

const AboutUs = () => {
    return (
        <Container fluid className="my-5 bg-light py-5">
            <Container>
                <Row className="mb-5">
                    <Col className="col-12 col-md-4 animate__animated animate__backInLeft">
                        <CardImg className="vh-50 rounded-5" src={data.aboutus_image} alt={data.aboutus_title} />
                    </Col>
                    <Col className="justify-content-start col-12 offset-md-1 col-md-7 animate__animated animate__backInRight">
                        <h1 className="text-start">{data.aboutus_title}</h1>
                        <p className="text-start fs-5">{ReactHtmlParser(data.aboutus_body)}</p>
                    </Col>
                </Row>
            </Container>
        </Container>
    );
};

export default AboutUs;
