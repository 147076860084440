import React from 'react';
import { Container } from 'react-bootstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faPhone, faLocationPin } from "@fortawesome/free-solid-svg-icons";
import { faInstagram, faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import data from '../data/tarpax-services';

const Footer = () => {

    return (
        <Container fluid style={{backgroundColor: '#f8f9fa', textAlign: 'center', padding: '10px'}}>
            <footer className="text-center text-lg-start bg-body-tertiary text-muted">
                <section className="d-flex justify-content-center justify-content-lg-between p-4 border-bottom">
                    <div className="me-5 d-none d-lg-block">
                        <span>Visita nuestras redes sociales:</span>
                    </div>
                    <div>
                        <a href={data.instagram} className="me-4 text-reset">
                            <FontAwesomeIcon icon={faInstagram} size="2xl" />
                        </a>
                    </div>
                </section>

                <section className="">
                    <div className="container text-center text-md-start mt-5">
                        <div className="row mt-3">
                            <div className="col-md-3 col-lg-4 col-xl-3 mx-auto mb-4">
                                <h4 className="text-uppercase fw-bold mb-4">
                                    <i className="fas fa-gem me-3"></i>{data.title}
                                </h4>
                                <h5>
                                    Fumigación y Desinfección
                                </h5>
                            </div>

                            <div className="col-md-3 col-lg-2 col-xl-2 mx-auto mb-4">
                                <h6 className="text-uppercase fw-bold mb-4">
                                    Conocer más
                                </h6>
                                <p>
                                    <a href="#!" className="text-reset">Nuestros clientes</a>
                                </p>
                                <p>
                                    <a href="#!" className="text-reset">Servicios</a>
                                </p>
                                <p>
                                    <a href="#!" className="text-reset">Cotización</a>
                                </p>
                            </div>

                            <div className="col-md-4 col-lg-3 col-xl-3 mx-auto mb-md-0 mb-4">
                                <h6 className="text-uppercase fw-bold mb-4">Visítanos</h6>
                                <p>
                                    <FontAwesomeIcon icon={faLocationPin} className="me-3" />
                                        <p className="mb-0">{data.address}</p>
                                </p>
                                <p>
                                    <FontAwesomeIcon icon={faEnvelope} className="me-3" />
                                    {data.email}
                                </p>
                                <p><FontAwesomeIcon icon={faPhone} className="me-3" /> {data.phone}</p>
                                <p><FontAwesomeIcon icon={faWhatsapp} className="me-3" /> {data.whatsapp}</p>
                            </div>
                        </div>
                    </div>
                </section>
                <div className="text-center p-4">
                    Desarrollado por:
                    <a className="text-reset fw-bold" href="https://www.coralanmultiservice.com/"> Coralan Multiservice</a>
                </div>
            </footer>
        </Container>
    );
};

export default Footer;
