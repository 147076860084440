import React from 'react';
import { Container, Row, Col, Image } from 'react-bootstrap';
import Masonry from 'react-masonry-css';

const masonryOptions = {
    transitionDuration: 0
};

const breakpointColumnsObj = {
    default: 5,
    1100: 3,
    700: 2,
    500: 1
  };

const PhotoGallery = ({ images }) => {
    const imageElements = images.map((image, index) => (
        <div><Image className='rounded-4' src={image.image} key={index} /></div>
    ));

  return (
    <Container>
        <Row>
            <Col>
                <h1 className='text-center display-4 mb-sm-5'>Clientes que han confiado en nosotros</h1>
            </Col>
        </Row>
      <Row>
        <Masonry
            breakpointCols={breakpointColumnsObj}
            className="my-masonry-grid"
            columnClassName="my-masonry-grid_column">
            {imageElements}
        </Masonry>
      </Row>
    </Container>
  );
};

export default PhotoGallery;