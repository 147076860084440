import { Navbar, Nav, Container } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone } from '@fortawesome/free-solid-svg-icons';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import data from '../data/tarpax-services';

const StickyNavbar = () => {
  return (
    <Container fluid className='bg-sticky d-none d-sm-block'>
      <Container>
        <Navbar className="sticky-header" expand="lg">
          <Navbar.Collapse className=' justify-content-end' id="sticky-navbar-nav">
            <Nav className="ml-auto">
            <p>
              <FontAwesomeIcon className="mx-2" icon={faEnvelope} size="1xl" /> {data.email}<FontAwesomeIcon className='mx-2' icon={faPhone} size="1xl" />{data.phone}
            </p>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </Container>
    </Container>
  );
};

export default StickyNavbar;