import React from 'react';
import { Navbar, Nav, Container } from 'react-bootstrap';
import data from '../data/tarpax-services';

const Header = () => {
    return (
        <Navbar className="gap-3 px-4 mt-4" bg="transparent" expand="md">
                <Container>
                <Navbar.Brand href="/">
                    <img
                        src={data.logo}
                        className="align-top brand-logo rounded-4"
                        alt={data.title}
                    />
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse className="justify-content-end" id="basic-navbar-nav">
                    <Nav className="flex-grow-1 justify-content-end align-items-center">
                        <Nav.Item className="mx-md-4">
                            <Nav.Link href="#quienes-somos">Nuestros clientes</Nav.Link>
                        </Nav.Item>
                        <Nav.Item className="dropdown">
                            <Nav.Link href="#servicios" className="dropdown-toggle" id="serviciosDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                Fumigación
                            </Nav.Link>
                            <ul className="dropdown-menu" aria-labelledby="serviciosDropdown">
                                <li><a className="dropdown-item" href="#metodo1">Métodos utilizados</a></li>
                                <li><a className="dropdown-item" href="#metodo2">Otros métodos</a></li>
                                <li><a className="dropdown-item" href="#condiciones">Condiciones y requisitos</a></li>
                                <li><a className="dropdown-item" href="#caracteristicas">Características del producto</a></li>
                            </ul>
                        </Nav.Item>
                        <Nav.Item className="dropdown">
                            <Nav.Link href="#servicios" className="dropdown-toggle" id="serviciosDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                Desinfección
                            </Nav.Link>
                            <ul className="dropdown-menu" aria-labelledby="serviciosDropdown">
                                <li><a className="dropdown-item" href="#metodo1">Proceso</a></li>
                                <li><a className="dropdown-item" href="#metodo2">Características del producto</a></li>
                                <li><a className="dropdown-item" href="#recomendaciones">Recomendaciones</a></li>
                                <li><a className="dropdown-item" href="#limpieza">Limpieza de pisos y colchones</a></li>
                            </ul>
                        </Nav.Item>
                        
                        <Nav.Item className="mx-md-4">
                            <Nav.Link href="#nuestros-servicios">Servicios</Nav.Link>
                        </Nav.Item>
                       <Nav.Item className="btn btn-info">
                            <Nav.Link href="#cotizacion" className="btn btn-info border-0 px-3">Cotización</Nav.Link>
                       </Nav.Item>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
};

export default Header;
