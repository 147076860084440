const data = {
  logo: "assets/images/logo.png",
  title: "TARPAX Services SRL",
  aboutus_title: "¿Quiénes Somos?",
  aboutus_image: "assets/images/aboutus.jpg",
  aboutus_body: "<p>Somos una empresa dominicana registrada con el RNC 130724431 que ofrece servicios de fumigación, desinfección, cristalizado, brillado, pulido y limpieza de pisos, limpieza de muebles y alfombras a nivel nacional. Abrimos nuestras puertas en el 2010,  por lo que contamos con más de 10 años de experiencia en el sector. Prestamos nuestros servicios a restaurantes, clínicas, oficinas, salón de belleza, tiendas,  residenciales, naves industriales, constructoras, entre otros.</p>"+
                "<p>Ofrecemos un servicio cercano y personalizado, marcamos una diferencia,  lo que nos permite identificar una solución más rápida en el control de las plagas, con un seguimiento continuo, que nos convierte en un integrante de su residencia u empresa.</p>" +
                "<h3 class='mb-4'>Propósito: Proveer un espacio que garantice el bienestar de todos.</h3>" +
                "<h3>Valores</h3>" +
                "<ul>" +
                  "<li>Actuamos con integridad</li>"+
                  "<li>Cuidamos a las personas y el entorno</li>"+
                  "<li>Encantamos al cliente</li>"+
                  "<li>Comprometidos con la solución</li>"+
                  "<li>Hacemos la diferencia</li>"+
                "</ul>",
  services_title: "Nuestros Servicios",
  services_body: "<p>Tarpax Services se centra principalmente en el control y la erradicación de plagas comunes, así como también, ofrecemos servicio de desinfección, el cual ayudara a prevenir posibles infecciones por agentes patógenos como los virus y bacterias.</p>"+
                "<p>Brindamos soluciones de control de plagas profesionales y efectivas que garantizan la salud y la higiene que uno necesita para vivir y trabajar en un entorno seguro, higiénico y libre de plagas.</p>"+
                "<p>Algunas de estas plagas que trabajamos son: cucarachas, moscas, mosquitos, comején, carcoma, hormigas, ratas, roedores, insectos rastreros y voladores en general, entre otros.</p>"+
                "<p>Contamos con horarios flexibles para su conveniencia. Respondemos el mismo día o dentro de 24 horas de haber recibido la solicitud del servicio.</p>"+
                "<p>Les ofrecemos un servicio profesional, con calidad, experiencia, seguridad y credibilidad, para garantizar el bienestar de nuestra gente, las mascotas y el medio ambiente.</p>"+
                "<h4>Su satisfacción es nuestra meta.</h4>",
  services_image: "assets/images/services.jpg",
  our_clients: [
    { 
      image: "assets/images/clients/1.jpg"
    },
    {
      image: "assets/images/clients/2.jpg"
    },
    { 
      image: "assets/images/clients/3.jpg"
    },
    { 
      image: "assets/images/clients/4.jpg"
    },
    { 
      image: "assets/images/clients/5.png"
    },
    { 
      image: "assets/images/clients/6.png"
    },
    { 
      image: "assets/images/clients/7.png"
    },
    {
      image: "assets/images/clients/8.png"
    },
    { 
      image: "assets/images/clients/9.jpg"
    },
    { 
      image: "assets/images/clients/10.jpg"
    },
    { 
      image: "assets/images/clients/11.png"
    },
    { 
      image: "assets/images/clients/12.jpg"
    },
  ],
  cta: {
      title: "Cotización",
      body: "Solicita tu cotización ahora",
      image: "",
      button: "Solicitar Cotización",
      link: "#",
      whatsapp: "8299362277"
  },
  address: 'Jardines del Embajador, Edificio 2BO, Apto 7O' +
      '\n' +
      'Bella Vista, Santo Domingo, DN\n' +
      '\n' +
      'República Dominicana',
  phone: '829-936-2277',
  whatsapp: '829-936-2277',
  email: 'tarpaxservicesrd@gmail.com',
  facebook: '',
  twitter: '',
  instagram: 'https://www.instagram.com/tarpaxservicesrd',
  linkedin: '',
  youtube: '',
  tiktok: '',
  // Add more data as needed
};

export default data;
