import React from 'react';
import PhotoGallery from '../components/PhotoGallery';
import AboutUs from './AboutUs';
import OurServices from './OurServices';
import data from '../data/tarpax-services';
import CallToAction from '../components/CallToAction';

const HomePage = () => {
    return (
        <>
            <AboutUs />
            <PhotoGallery images={data.our_clients} />
            <CallToAction cta={data.cta} />
            <OurServices />
        </>
    );
};

export default HomePage;
